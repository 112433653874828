import { invokeApi } from "../../bl_libs/invokeApi";

export const booksListApi = async (slug) => {
  const requestObj = {
    path: `/api/book/book_list_by_category/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const activeProgrammesCategoryListApi = async (data) => {
  const requestObj = {
    path: `/api/program_category/active_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addBookApi = async (data) => {
  const requestObj = {
    path: `/api/book/add_book`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editBookApi = async (data, slug) => {
  const requestObj = {
    path: `/api/book/book_update/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteBookApi = async (slug) => {
  const requestObj = {
    path: `/api/book/delete_book/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
