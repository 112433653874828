import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { defaultMenuListApi } from "src/DAL/Menu/Menu";
import { addInvoiceSettingApi } from "src/DAL/SiteSetting/siteSetting";
import { s3baseUrl } from "src/config/config";
import { useEffect } from "react";
import { clientSettingApi } from "src/DAL/ClientSetting/ClientSetting";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import TinyEditor from "src/components/ckeditor/Ckeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function InvoiceSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [invoiceImage, setInvoiceImage] = useState();
  const [oldInvoiceImage, setOldInvoiceImage] = useState("");
  const [oldLogo, setOldLogo] = useState("");
  const [dynamiteDigitalAddress, setDynamiteDigitalAddress] = useState("");
  const [paymentInfo, setPaymentInfo] = useState("");

  const [inputs, setInputs] = useState({
    logo: {},
    InvoiceImage: {},
    dynamite_digital_address: "",
    invoice_from: "",
  });

  const getDefaultSetting = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code === 200) {
      console.log(result.content_setting, "result.content_setting");
      setInputs(result.content_setting.invoice_setting);
      setPaymentInfo(result?.content_setting.invoice_setting?.footer_content);
      setDynamiteDigitalAddress(
        result?.content_setting.invoice_setting?.markus_address
      );
      // setOldInvoiceImage(
      //   result?.content_setting.invoice_setting?.invoice_image
      // );
      setOldLogo(result?.content_setting?.invoice_setting?.invoice_logo);
      setIsLoading(false);
    }
  };

  const fileChangedHandler = async (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "600");

    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["logo"]: imageUpload.image_path,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const fileChangedHandlerFB = async (e) => {
    setInvoiceImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "600");

    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["InvoiceImage"]: imageUpload.image_path,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      invoice_logo: inputs.logo,

      invoice_from: inputs?.invoice_from,
      markus_address: dynamiteDigitalAddress,
      footer_content: paymentInfo,
    };

    // if (invoiceImage) {
    //   postData.invoice_image = inputs.InvoiceImage;
    // } else {
    //   postData.invoice_image = oldInvoiceImage;
    // }

    if (file) {
      postData.invoice_logo = inputs.logo;
    } else {
      postData.invoice_logo = oldLogo;
    }
    let formData = {
      invoice_setting: postData,
    };
    const result = await addInvoiceSettingApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getDefaultSetting();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>Invoice Setting</h2>
        </div>
      </div>
      <div>
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Invoice From"
                variant="outlined"
                fullWidth
                name="invoice_from"
                required
                value={inputs?.invoice_from}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <h4>Markus Address *</h4>
              <TinyEditor
                setDetailDescription={setDynamiteDigitalAddress}
                detailDescriptionCk={dynamiteDigitalAddress}
                editorHeight={300}
              />
            </div>

            <div className="col-12 mt-3">
              <h4>Payment Info *</h4>
              <TinyEditor
                setDetailDescription={setPaymentInfo}
                detailDescriptionCk={paymentInfo}
                editorHeight={300}
              />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <div className="row w-100 div-style ms-0 pt-0">
                <div className="col-5">
                  <p className="">Invoice Logo</p>
                  <FormHelperText className="pt-0">
                    (Recommended Size 250 X 100)
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {file ? (
                    <img className="image-border" src={file} height="50" />
                  ) : (
                    oldLogo && (
                      <img
                        className="image-border"
                        src={s3baseUrl + oldLogo}
                        height="50"
                      />
                    )
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Button
                      className="small-contained-button"
                      startIcon={<FileUploadIcon />}
                      component="span">
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
              {inputs?.logo?.name == "" ? (
                ""
              ) : (
                <p className="text-secondary">{inputs?.logo?.name}</p>
              )}
            </div>
            {/*
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <div className="row w-100 div-style ms-0 pt-0">
                <div className="col-5">
                  <p className="">Invoice Image</p>
                  <FormHelperText className="pt-0">
                    (Recommended Size 1000 X 250)
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {invoiceImage ? (
                    <img
                      className="image-border"
                      src={invoiceImage}
                      height="50"
                    />
                  ) : (
                    oldInvoiceImage && (
                      <img
                        className="image-border"
                        src={s3baseUrl + oldInvoiceImage}
                        height="50"
                      />
                    )
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-fileFb">
                    <Input
                      accept="image/*"
                      id="contained-button-fileFb"
                      multiple
                      type="file"
                      name="InvoiceImage"
                      onChange={fileChangedHandlerFB}
                    />

                    <Button
                      className="small-contained-button"
                      startIcon={<FileUploadIcon />}
                      component="span">
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
              {inputs?.logo?.name == "" ? (
                ""
              ) : (
                <p className="text-secondary">{inputs?.logo?.name}</p>
              )}
            </div>*/}
          </div>
        </div>
      </div>
      <div className="text-end mt-4">
        <button
          onClick={handleSubmit}
          className="small-contained-button"
          id="fixedbutton">
          Submit
        </button>
      </div>
    </div>
  );
}
