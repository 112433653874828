import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress } from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";
import { object } from "prop-types";
import { defaultMenuListApi, menuList } from "src/DAL/Menu/Menu";
import {
  AddProgrammeApi,
  programmeActiveListing,
} from "src/DAL/Programme/Programme";
import TinyEditor from "../../components/ckeditor/Ckeditor";
import {
  addDefaultSettingApi,
  defaultSettingApi,
  siteSettingApi,
} from "src/DAL/SiteSetting/siteSetting";
import {
  AddEmailSettingApi,
  emailSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { s3baseUrl } from "src/config/config";
import { TimeZones } from "../../utils/constant";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DefaultSetting() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setiswaiting] = useState(false);
  const theme = useTheme();
  const [value, setValue] = React.useState("Controlled");
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [fb, setFBImage] = React.useState();
  const [instagram, setInstagramImage] = React.useState();
  const [linkedInImage, setLinkedInImage] = React.useState();
  const [twitterImage, setTwitterImage] = React.useState();
  const [audioFile, setAudio] = React.useState();
  const [groups, setGroups] = React.useState([]);
  const [programList, setProgramList] = React.useState([]);
  const [menuLists, setMenuList] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  const [programName, setProgramName] = React.useState("");
  const [vaultName, setVaultName] = React.useState("");
  const [navItems, setNavitems] = React.useState([]);
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState("");
  const [oldFb, setOldFb] = useState("");
  const [oldBackgroundImage, setOldBackgroundImage] = useState("");
  const [oldForgotBackgroundImage, setOldForgotBackgroundImage] = useState("");
  const [oldResetBackgroundImage, setOldResetBackgroundImage] = useState("");
  const [oldLogo, setOldLogo] = useState("");
  const [oldTwitter, setOldTwitter] = useState("");
  const [oldInstagram, setOldInstagram] = useState("");
  const [groupsName, setGroupsName] = React.useState([]);
  const [programmeListing, setProgramListing] = React.useState([]);
  const [backgroundImage, setBackgroundImage] = useState();
  const [forgotBackgroundImage, setForgotBackgroundImage] = useState();
  const [resetBackgroundImage, setResetBackgroundImage] = useState();
  const [inputValue, setInputValue] = React.useState("");
  const [timeZoneValue, setTimeZoneValue] = React.useState("Europe/Dublin");
  const [inputs, setInputs] = React.useState({
    image: {},
    facebookIcon: {},
    title: "",
    description: "",
    time_zone: "europe/berlin",
    backgroundImage: {},
    forgotBackgroundImage: {},
    resetBackgroundImage: {},
  });

  const getNavItemList = async () => {
    const result = await defaultMenuListApi();
    if (result.code === 200) {
      //console.log(result, "resultresultresultresultresultresultresult");
      setMenuList(result.menus);
      //   setVaultName(result.vault_category[0].vault_slug);
    }
  };
  const getDefaultSetting = async () => {
    const result = await defaultSettingApi();
    if (result.code === 200) {
      console.log(result, "result of setting");
      let groups_slug = result?.default_setting?.default_access_program;
      let selected_group = [];
      groups_slug.map((group) => {
        if (group == undefined) {
        } else {
          selected_group.push(group?._id?.program_slug);
        }
      });
      setGroupsName(selected_group);
      setTimeZoneValue(result?.default_setting?.time_zone);
      setInputs({
        ...inputs,
        ["title"]: result?.default_setting?.meta_title,
        ["description"]: result?.default_setting?.meta_description,
        // ["time_zone"]: result?.default_setting?.time_zone,
      });
      setOldFb(result?.default_setting?.admin_favicon);
      setOldLogo(result?.default_setting?.admin_logo);
      setProgramName(result?.default_setting?.default_program?._id);
      setNavitems(result?.default_setting?.nav_items);
      setOldBackgroundImage(result?.default_setting?.login_background_image);
      setOldForgotBackgroundImage(
        result?.default_setting?.forgot_password_background_image
      );
      setOldResetBackgroundImage(
        result?.default_setting?.reset_password_background_image
      );
    }
  };
  const getProgrammes = async () => {
    setIsLoading(true);
    const result = await programmeActiveListing();
    if (result.code === 200) {
      const programArray = result.program;
      let a = programArray.unshift({ _id: "", title: "None" });

      setProgramListing(programArray);
      setProgramList(programArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeNavItem = (event) => {
    const {
      target: { value },
    } = event;
    setNavitems(
      // On autofill we get a stringified value.
      typeof value === "st  ring" ? value.split(",") : value
    );
  };
  ////console.log(navItems, "navItemsnavItems");
  const getProgramListing = async () => {
    // const result = await programmeActiveListing();
    // setIsLoading(true);
    // if (result.code === 200) {
    //   ////console.log(result, "getProgramListing");
    //   setProgramList(result.program);
    //   setIsLoading(false);
    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    //   setIsLoading(false);
    // }
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const fileChangedHandlerFB = (e) => {
    setFBImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["facebookIcon"]: e.target.files[0],
    });
  };
  const fileChangedHandlerBackgroundImage = (e) => {
    setBackgroundImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["backgroundImage"]: e.target.files[0],
    });
  };
  const fileChangedHandlerForgotBackgroundImage = (e) => {
    setForgotBackgroundImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["forgotBackgroundImage"]: e.target.files[0],
    });
  };
  const fileChangedHandlerResetBackgroundImage = (e) => {
    setResetBackgroundImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["resetBackgroundImage"]: e.target.files[0],
    });
  };

  const handleChangeGroup = (event) => {
    const {
      target: { value },
    } = event;
    setGroupsName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  ////console.log(groupsName, "groupsNamegroupsName");

  const handleSubmit = async (e) => {
    ////console.log(detailDescriptionCk, "lolololo");
    e.preventDefault();
    let group_array1 = [];
    let group_object = {};
    groupsName.map((group) => {
      console.log(group, "nulllllllllllllll");
      if (group == undefined) {
      } else {
        group_object = {
          program_slug: group,
        };
        group_array1.push(group_object);
      }
    });
    let navArray = [];
    navArray.push(navItems);
    ////console.log(group_array1, "group_array");
    ////console.log(navArray, "navItemsnavItemsnavItemsnavItems");

    const formData = new FormData();
    formData.append("meta_title", inputs.title);
    formData.append("meta_description", inputs.description);
    formData.append("nav_items", JSON.stringify(navItems));
    formData.append("time_zone", timeZoneValue);
    formData.append("contact_us_support_email", "DLX@gmail.com");
    formData.append("default_access_program", JSON.stringify(group_array1));
    formData.append("default_program", programName ? programName : "");

    if (fb) {
      formData.append("admin_favicon", inputs.facebookIcon);
    }

    if (file) {
      formData.append("admin_logo", inputs.image);
    }
    if (backgroundImage) {
      formData.append("login_background_image", inputs.backgroundImage);
    }
    if (forgotBackgroundImage) {
      formData.append(
        "forgot_password_background_image",
        inputs.forgotBackgroundImage
      );
    }
    if (resetBackgroundImage) {
      formData.append(
        "reset_password_background_image",
        inputs.resetBackgroundImage
      );
    }

    //console form data

    // console.log(...formData, "form data value");

    setIsLoading(true);
    const result = await addDefaultSettingApi(formData);
    if (result.code === 200) {
      ////console.log(result, "result");
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      ////console.log(result, "error case");
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChangeProgram = (e) => {
    console.log(e.target.value, "prrrrrrrr");
    setProgramName(e.target.value);
  };
  const handleChangeVault = (event) => {
    setVaultName(event.target.value);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    getNavItemList();
    getProgramListing();
    getProgrammes();
    getDefaultSetting();
  }, []);
  //console.log(menuLists, "menuListsmenuListsmenuLists ");

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  // console.log(programmeListing, "programmeListing");
  console.log(programList, "programList");
  console.log(programName, "programName");

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/addreview/`)}
          >
            Add Programme Review
          </button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Default Setting</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Default Programme
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={programName}
              label="Default Programme"
              onChange={handleChangeProgram}
            >
              {programList.map((program, i) => {
                return (
                  <MenuItem key={i} value={program._id}>
                    {program.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <Autocomplete
            value={timeZoneValue}
            onChange={(event, newValue) => {
              setTimeZoneValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={TimeZones}
            renderInput={(params) => (
              <TextField {...params} label="Time Zone " />
            )}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Logo</p>
              <FormHelperText className="pt-0">
                (Recommended Size 778 X 430)
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img src={file} height="50" />
              ) : (
                <img src={s3baseUrl + oldLogo} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image.name}</p>
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Favicon</p>
              <FormHelperText className="pt-0">
                (Recommended Size 32 X 32)
              </FormHelperText>
            </div>
            <div className="col-2">
              {fb ? (
                <img src={fb} height="50" />
              ) : (
                <img src={s3baseUrl + oldFb} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-fileFb">
                <Input
                  accept="image/*"
                  id="contained-button-fileFb"
                  multiple
                  type="file"
                  name="facebookIcon"
                  onChange={fileChangedHandlerFB}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image.name}</p>
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Background Image</p>
              <FormHelperText className="pt-0">
                (Recommended Size 3584 X 1836)
              </FormHelperText>
            </div>
            <div className="col-2">
              {backgroundImage ? (
                <img src={backgroundImage} height="50" />
              ) : (
                oldBackgroundImage && (
                  <img src={s3baseUrl + oldBackgroundImage} height="50" />
                )
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-fileBackgroundImage">
                <Input
                  accept="image/*"
                  id="contained-button-fileBackgroundImage"
                  multiple
                  type="file"
                  name="backgroundImage"
                  onChange={fileChangedHandlerBackgroundImage}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image.name}</p>
          )}
        </div>{" "}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Forgot Password Background Image</p>
              <FormHelperText className="pt-0">
                (Recommended Size 3584 X 1836)
              </FormHelperText>
            </div>
            <div className="col-2">
              {forgotBackgroundImage ? (
                <img src={forgotBackgroundImage} height="50" />
              ) : (
                oldForgotBackgroundImage && (
                  <img src={s3baseUrl + oldForgotBackgroundImage} height="50" />
                )
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-fileForgotBackgroundImage">
                <Input
                  accept="image/*"
                  id="contained-button-fileForgotBackgroundImage"
                  multiple
                  type="file"
                  name="forgotBackgroundImage"
                  onChange={fileChangedHandlerForgotBackgroundImage}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image.name}</p>
          )}
        </div>{" "}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Reset Password Background Image</p>
              <FormHelperText className="pt-0">
                (Recommended Size 3584 X 1836)
              </FormHelperText>
            </div>
            <div className="col-2">
              {resetBackgroundImage ? (
                <img src={resetBackgroundImage} height="50" />
              ) : (
                oldResetBackgroundImage && (
                  <img src={s3baseUrl + oldResetBackgroundImage} height="50" />
                )
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-fileResetBackgroundImage">
                <Input
                  accept="image/*"
                  id="contained-button-fileResetBackgroundImage"
                  multiple
                  type="file"
                  name="resetBackgroundImage"
                  onChange={fileChangedHandlerResetBackgroundImage}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image.name}</p>
          )}
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Meta Title"
            variant="outlined"
            fullWidth
            name="title"
            required
            value={inputs.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Meta Description"
            variant="outlined"
            fullWidth
            name="description"
            required
            value={inputs.description}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-name-label">Nav Items</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={navItems}
              onChange={handleChangeNavItem}
              input={<OutlinedInput label="Nav Items" />}
              MenuProps={MenuProps}
            >
              {menuLists.map((name) => (
                <MenuItem
                  key={name}
                  value={name.option_value}
                  style={getStyles(name, navItems, theme)}
                >
                  {name.option_label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <FormControl className="mt-3" fullWidth>
            <InputLabel id="demo-multiple-name-label">
              Default Programmes Access
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={groupsName}
              onChange={handleChangeGroup}
              input={<OutlinedInput label="Default Programmes Access" />}
              MenuProps={MenuProps}
            >
              {programmeListing?.map((name) => (
                <MenuItem
                  key={name}
                  value={name?.program_slug}
                  style={getStyles(name, groupsName, theme)}
                >
                  {name?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
