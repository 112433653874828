import { invokeApi } from "../../bl_libs/invokeApi";
export const pagesListingApi = async (data) => {
  const requestObj = {
    path: `/api/sale_page`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteSalePageApi = async (slug) => {
  const requestObj = {
    path: `/api/sale_page/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const templateListingApi = async (data) => {
  const requestObj = {
    path: `/api/template_configuration/active_template_configuration`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const salePageEventDetailApi = async (id) => {
  const requestObj = {
    path: `/api/event/detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addWebsiteEvent = async (data) => {
  const requestObj = {
    path: `/api/home_event/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editWebsiteEvent = async (data, id) => {
  const requestObj = {
    path: `/api/home_event/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteWebsiteEvent = async (id) => {
  const requestObj = {
    path: `/api/home_event/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addPageApi = async (data) => {
  const requestObj = {
    path: `/api/sale_page`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editPageApi = async (data, id) => {
  const requestObj = {
    path: `/api/sale_page/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const socail_links = async (data, id) => {
  const requestObj = {
    path: `/api/sale_page/update_page/social_sharing/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const page_detail_by_id = async (id) => {
  const requestObj = {
    path: `/api/sale_page/sale_page_detail_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const detailPageApi = async (id) => {
  const requestObj = {
    path: `/api/sale_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const detailContentPageApi = async (id) => {
  const requestObj = {
    path: `/api/sale_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const updatePageContentSettingApi = async (data, id) => {
  const requestObj = {
    path: `/api/sale_page/update_sale_page_detail/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const websiteEventListApi = async (page_id) => {
  let apiPath = "";
  if (page_id.id) {
    apiPath = `/api/home_event/home_event_by_sale_page/${page_id?.id}`;
  } else {
    apiPath = "/api/home_event/";
  }
  const requestObj = {
    path: apiPath,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const list_thanks_page = async (id) => {
  const requestObj = {
    path: `/api/sale_page/social_sharing/thanks_page_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
