import React, { useState, useEffect } from "react";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Button,
  CircularProgress,
  IconButton,
  Input,
  TextField,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import FormHelperText from "@mui/material/FormHelperText";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import {
  addWebsiteEvent,
  editWebsiteEvent,
  page_detail_by_id,
  salePageEventDetailApi,
} from "src/DAL/WebsitePages/websitepages";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import { handleMenu } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const EditWebsiteEvent = () => {
  const classes = useStyles();
  const page_id = useParams();
  const [date, setDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [file, setProfileImage] = React.useState();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [oldImage, setOldImage] = useState("");
  const [shortDescriptionCk, setShortDescriptionCk] = useState("");
  const { state } = useLocation();

  const [inputs, setInputs] = useState({
    title: "",
    buttonLink: "",
    buttonText: "",
    short_description: "",
    status: "",
    image: {},
    order: "",
  });
  console.log(state, "params.stateparams.state");
  console.log(page_id, "page_id");
  const [moduleData, setModuleData] = useState({});

  const getPageDetail = async () => {
    // setIsLoading(true);
    const result = await page_detail_by_id(page_id.page_id);
    if (result.code == 200) {
      const get_module_info = result.sale_page.module_info.filter(
        (item) => item.module_actual_name == module_actual_name
      )[0];
      setModuleData(get_module_info.module_replica_info);
      setIsLoading(false);
    } else {
      // enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const getEventDetail = async () => {
    const result = await salePageEventDetailApi(page_id.id);
    if (result.code == 200) {
      console.log(result, "result of events");
      let data = result.event;
      setInputs({
        ...inputs,
        ["title"]: data.title,
        ["buttonLink"]: data.button_link,
        ["buttonText"]: data.button_text,
        ["short_description"]: data.description,
        ["status"]: data.status,
        ["image"]: images.thumbnail_1,
        ["order"]: data.order,
      });
    }
  };
  console.log(date, "dateeeee");
  const handleChangeDate = (newValue) => {
    let todayDate = moment(newValue).format("YYYY-MM-DD");
    let dateType = todayDate.toString();
    setDate(dateType);
  };
  const handleChangeEndDate = (newValue) => {
    let todayDate = moment(newValue).format("YYYY-MM-DD");
    let dateType = todayDate.toString();
    console.log(typeof dateType, "dateType");
    setEndDate(todayDate);
  };

  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("button_text", inputs.buttonText);
    formData.append("button_link", inputs.buttonLink);
    formData.append("description", shortDescriptionCk);
    formData.append("status", inputs.status);
    formData.append("order", inputs.order);
    formData.append("event_start_date", date);

    if (page_id?.page_id) {
      formData.append("created_for", "sale_page");
      formData.append("page_id", page_id.page_id);
    } else {
      formData.append("created_for", "general");
    }

    // if (page_id?.page_id) {
    //   formData.append("created_for", "sale_page");
    //   formData.append("page_id", page_id.page_id);
    // } else {
    //   const formData = new FormData();
    //   formData.append("title", inputs.title);
    //   formData.append("button_text", inputs.buttonText);
    //   formData.append("button_link", inputs.buttonLink);
    //   formData.append("description", shortDescriptionCk);
    //   formData.append("status", inputs.status);
    //   formData.append("event_start_date", date);

    //   formData.append("order", inputs.order);
    //   if (page_id?.page_id) {
    //     formData.append("created_for", "sale_page");
    //     formData.append("page_id", page_id.page_id);
    //   } else {
    //     formData.append("created_for", "general");
    //   }
    // }
    if (file) {
      formData.append("image", inputs.image);
    }

    console.log(...formData, "formData");
    let id = page_id.page_id ? state.value.slug : state.slug;

    setIsLoading(true);
    const result = await editWebsiteEvent(formData, id);
    if (result.code === 200) {
      console.log(result, "result");
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      console.log(result, "error case");
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let getValue = page_id.page_id ? state.value.object : state.object;
  const {
    button_link,
    button_text,
    description,
    event_end_date,
    event_start_date,
    images,
    order,
    status,
    title,
  } = getValue;
  // page_id?.page_id ? state?.value?.object : state?.object
  useEffect(() => {
    // getEventDetail();
    setDate(event_start_date);
    setEndDate(event_end_date);
    setOldImage(images?.thumbnail_1);
    setShortDescriptionCk(description);
    setInputs({
      ...inputs,
      ["title"]: title,
      ["buttonLink"]: button_link,
      ["buttonText"]: button_text,
      ["short_description"]: description,
      ["status"]: status,
      ["image"]: images?.thumbnail_1,
      ["order"]: order,
    });
  }, []);
  useEffect(() => {
    if (state.params) {
      setModuleData(state.params.state);
    } else {
      getPageDetail();
      setModuleData(state?.params?.state);
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(state, "moduleData");

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/addreview/`)}
          >
            Add Programme Review
          </button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>
            {moduleData
              ? moduleData?.module_replica_info?.edit_page_heading
              : "Edit Event"}
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            fullWidth
            required
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Button Text"
            variant="outlined"
            fullWidth
            name="buttonText"
            value={inputs.buttonText}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Order"
            variant="outlined"
            fullWidth
            name="order"
            value={inputs.order}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Button Link"
            variant="outlined"
            fullWidth
            name="buttonLink"
            value={inputs.buttonLink}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Event Start Date *"
                // inputFormat="MM/dd/yyyy"
                inputFormat="yyyy/MM/dd"
                value={date}
                onChange={handleChangeDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </div>
        {/*
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Event End Date"
                // inputFormat="MM/dd/yyyy"
                inputFormat="yyyy/MM/dd"
                value={endDate}
                onChange={handleChangeEndDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </div> */}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Status"
              required
              onChange={handleChange}
            >
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image *</p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img src={file} height="50" />
              ) : (
                <img src={s3baseUrl + oldImage} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  hidden
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.image?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.image?.name}</p>
          )}
        </div>

        {/* <div className="col-12 mt-5">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description "
              multiline
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
            <FormHelperText>Maximum limit 500 characters</FormHelperText>
          </FormControl>
        </div> */}
        <div className="col-12 mt-4">
          <h4>Short Description </h4>
          <TinyEditor
            setDetailDescription={setShortDescriptionCk}
            detailDescriptionCk={shortDescriptionCk}
          />
        </div>
        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditWebsiteEvent;
