import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import { project_name } from "src/config/config";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SettingsIcon from "@mui/icons-material/Settings";
import { Icon } from "@iconify/react";
import { ListItemIcon } from "@mui/material";
// ----------------------------------------------------------------------
const getMUIIcon = (name) => {
  return <ListItemIcon className="navbar-icon">{name}</ListItemIcon>;
};

const getEvaIcon = (name) => (
  <Icon className="navbar-icon" icon={name} width={22} height={22} />
);

const sidebarConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: getEvaIcon(pieChart2Fill),
  },
  {
    title: "Programmes",
    path: "/programmes",
    icon: getEvaIcon(barchartfill),
  },
  {
    title: "Members",
    path: "/member",
    icon: getEvaIcon(barchartfill),
  },
  {
    title: "Consultant",
    path: "/consultant",
    icon: getEvaIcon(barchartfill),
  },
  {
    title: "Goal Statement",
    path: "/goal-statement",
    icon: getEvaIcon(barchartfill),
  },
  {
    title: "News Letter",
    path: "/news-letter",
    icon: getEvaIcon(barchartfill),
  },
  // {
  //   title: "90 Day Questions",
  //   path: "/90-day-questions",
  //   icon: getEvaIcon(barchartfill),
  // },
  {
    title: "Books",
    path: "/books",
    icon: getEvaIcon("mdi:bookshelf"),
  },
  {
    title:
      project_name == "basic-dev"
        ? "Dynamite Recording"
        : project_name == "dynamite-lifestyle-dev"
        ? "Kim's Recording"
        : project_name == "danielle-dev"
        ? "Danielle's Recording"
        : project_name == "hina-khan-dev"
        ? "Hina's Recording"
        : project_name == "dev-feature"
        ? "Kim's Recording"
        : project_name == "markus-dev"
        ? "Markus's Recording"
        : "Markus's Recording",
    icon: getMUIIcon(<DateRangeIcon />),
    child_options: [
      {
        title: "Recordings",
        path: "/recording-list",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Categories",
        path: "/vault",
        icon: getMUIIcon(<DateRangeIcon />),
      },
    ],
  },
  // {
  //   title: "Study Session",
  //   icon: getMUIIcon(<DateRangeIcon />),
  //   child_options: [
  //     {
  //       title: "Sessions",
  //       path: "/sessions",
  //       icon: getMUIIcon(<DateRangeIcon />),
  //     },
  //     {
  //       title: "Session Recording",
  //       path: "/session-recording",
  //       icon: getMUIIcon(<DateRangeIcon />),
  //     },
  //   ],
  // },
  {
    title: "Extras",
    icon: getMUIIcon(<DateRangeIcon />),
    child_options: [
      {
        title: "Quotes",
        path: "/quotesList",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Gratitude",
        path: "/gratitude",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Affirmation",
        path: "/affirmation",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Affirmation Categories",
        path: "/affirmations-categories",
        icon: getMUIIcon(<DateRangeIcon />),
      },
    ],
  },
  {
    title: "Payments",
    icon: getMUIIcon(<DateRangeIcon />),
    child_options: [
      {
        title: "Stripe Products",
        path: "/stripe-products",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Transactions",
        path: "/payment-transactions",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Payment Templates",
        path: "/payment-template",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Payment Request",
        path: "/payment-request",
        icon: getMUIIcon(<DateRangeIcon />),
      },
    ],
  },
  {
    title: "Calendar",
    icon: getMUIIcon(<DateRangeIcon />),
    child_options: [
      {
        title: "Groups",
        path: "/calendar-groups",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Calendar Events",
        path: "/calendarEvents",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Pods",
        path: "/calendar-pods",
        icon: getMUIIcon(<DateRangeIcon />),
      },
    ],
  },
  {
    title: "Support",
    icon: getMUIIcon(<DateRangeIcon />),
    child_options: [
      {
        title: "Departments",
        path: "/departments",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Support Tickets",
        path: "/support-ticket",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Trash Support Tickets",
        path: "/trash-support-ticket",
        icon: getMUIIcon(<DateRangeIcon />),
      },
    ],
  },

  {
    title: "Client Portal",
    icon: getMUIIcon(<SettingsIcon />),
    child_options: [
      {
        title: "General Setting",
        path: "/general-setting",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Client Dashboard",
        path: "/client-dashboard",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Content Setting",
        path: "/content-setting",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Testimonials",
        path: "/website-testimonials",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Events",
        path: "/website-events",
        icon: getMUIIcon(<DateRangeIcon />),
      },
    ],
  },

  // {
  //   title: "Consultant Portal",
  //   icon: getMUIIcon(<SettingsIcon />),
  //   child_options: [
  //     {
  //       title: "General Setting",
  //       path: "/consultants-general-setting",
  //       icon: getMUIIcon(<DateRangeIcon />),
  //     },
  //   ],
  // },

  {
    title: "General Setting",
    icon: getMUIIcon(<SettingsIcon />),
    child_options: [
      {
        title: "Default Setting",
        path: "/default-setting",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Email Setting",
        path: "/email-setting",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Stripe Setting",
        path: "/add-stripe",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Campaign Setting",
        path: "/add-compaign",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      {
        title: "Invoice Setting",
        path: "/invoice-setting",
        icon: getMUIIcon(<DateRangeIcon />),
      },
      // {
      //   title: "SendGrid Setting",
      //   path: "/send-grid",
      //   icon: getMUIIcon(<DateRangeIcon />),
      // },
      {
        title: "Email Templates",
        path: "/email-templates",
        icon: getMUIIcon(<DateRangeIcon />),
      },
    ],
  },
  {
    title: "Manage Website",
    icon: getMUIIcon(<SettingsIcon />),
    child_options: [
      {
        title: "Pages",
        path: "/website-pages",
        icon: getMUIIcon(<DateRangeIcon />),
      },

      // {
      //   title: "Payment Plans",
      //   path: "/website-general-payment-plans",
      //   icon: getMUIIcon(<DateRangeIcon />),
      // },

      // {
      //   title: "FAQ",
      //   path: "/website-faq",
      //   icon: getMUIIcon(<DateRangeIcon />),
      // },
      // {
      //   title: "Website Podcasts",
      //   path: "/website-podcasts",
      //   icon: getMUIIcon(<DateRangeIcon />),
      // },
      // {
      //   title: "Website Programme Category",
      //   path: "/website-categories",
      //   icon: getMUIIcon(<DateRangeIcon />),
      // },
      // {
      //   title: "Website Programmes",
      //   path: "/website-programmes",
      //   icon: getMUIIcon(<DateRangeIcon />),
      // },
      // {
      //   title: "Business Strategy",
      //   path: "/business-strategy",
      //   icon: getMUIIcon(<DateRangeIcon />),
      // },
      // {
      //   title: "Business Partner",
      //   path: "/business-partner",
      //   icon: getMUIIcon(<DateRangeIcon />),
      // },
      // {
      //   title: "Team",
      //   path: "/website-team",
      //   icon: getMUIIcon(<DateRangeIcon />),
      // },
      // {
      //   title: "Services",
      //   path: "/website-services",
      //   icon: getMUIIcon(<DateRangeIcon />),
      // },
      // {
      //   title: "Banner Slider",
      //   path: "/website-banner-slider",
      //   icon: getMUIIcon(<DateRangeIcon />),
      // },
      // {
      //   title: "Book Category",
      //   path: "/book-categories",
      //   icon: getMUIIcon(<DateRangeIcon />),
      // },
      {
        title: "Menus",
        path: "/menu",
        icon: getMUIIcon(<DateRangeIcon />),
      },
    ],
  },
];

export default sidebarConfig;
