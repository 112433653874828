import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Input,
  TextField,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import {
  detailWebsiteTestimonialApi,
  editWebsiteTestimonialApi,
} from "../../DAL/Testimonial/testimonial";
import { urlPatternValidation } from "src/utils/constant";
import { FaqDetailApi, updateFaqApi } from "src/DAL/FAQ/FaqApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const EditWebPageFaq = () => {
  const classes = useStyles();
  const page_id = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [inputs, setInputs] = useState({
    question_statment: "",
    status: true,
    answer_statment: "",
    order: 0,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getFaqDetailData = async () => {
    const result = await FaqDetailApi(page_id.id);
    setInputs(result.faq);
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (inputs.answer_statment.length > 500) {
      enqueueSnackbar("Description length must be less than 500 characters !", {
        variant: "error",
      });
    } else {
      setIsLoading(true);
      formData.append("question_statment", inputs.question_statment);
      formData.append("answer_statment", inputs.answer_statment);
      formData.append("status", inputs.status);
      formData.append("order", inputs.order);
      if (page_id && page_id.page_id) {
        formData.append("created_for", "sale_page");
        formData.append("page_id", page_id.page_id);
      } else {
        formData.append("created_for", "general");
      }

      const result = await updateFaqApi(formData, page_id.id);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getFaqDetailData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-6">
          <h2>
            {state.module_replica_info.edit_page_heading
              ? state.module_replica_info.edit_page_heading
              : "Edit FAQ"}
          </h2>
        </div>
      </div>
      <form method="post" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Question Statment"
              variant="outlined"
              fullWidth
              required
              name="question_statment"
              value={inputs.question_statment}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Faq Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Faq Status"
                required
                onChange={handleChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Order"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="order"
              value={inputs.order}
              onChange={handleChange}
              onWheel={(event) => event.target.blur()}
            />
          </div>

          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Answer Statment"
                multiline
                rows={6}
                required
                name="answer_statment"
                value={inputs.answer_statment}
                onChange={handleChange}
              />
              <FormHelperText>Maximum limit 500 characters</FormHelperText>
            </FormControl>
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditWebPageFaq;
