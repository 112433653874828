import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  PrivateRouteWithLayout,
  // RouteWithLayout,
  PublicRouteWithLayout,
  // PrivateRouteWithLayout,
} from "./components";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import {
  ForgetPassword,
  Programmes,
  ProgrammesDetail,
  GoalStatement,
  Vault,
  Profile,
  Pods,
  Affirmations,
  Gratitude,
  Quotes,
  Memories,
  MemoriesDetails,
  DailyActivities,
  Calender,
  LessonsDetail,
  LessonListing,
  ProgrammRecordingDetail,
  AffirmationList,
} from "./pages/index";
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Products from "./pages/Products";
import Blog from "./pages/Blog";
import User from "./pages/User";
import NotFound from "./pages/Page404";
import AddGratitude from "./pages/Gratitude/AddGratitude";
import AddProgramme from "./pages/Programmes/AddProgramme";
import EditProgramme from "./pages/Programmes/EditProgramme";
import AddLesson from "./pages/Programmes/AddLesson";
import EditLesson from "./pages/Programmes/EditLesson";
import LessonRecordingListing from "./pages/LessonRecording/LessonRecordingListing";
import LessonRecordingDetail from "./pages/LessonRecording/LessonRecordingDetail";
import AddLessonRecording from "./pages/LessonRecording/AddLessonRecording";
import EditLessonRecording from "./pages/LessonRecording/EditLessonRecording";
import { ResourcesCard } from "./pages/LessonRecording/ResourcesCard";
import AddLessonDocument, {
  AddLessonDecoument,
} from "./pages/LessonRecording/AddLessonDocument";
import EditLessonDocument from "./pages/LessonRecording/EditLessonDocument";
import { ProgrammeDocumentList } from "./pages/Programmes/ProgrammeDocumentList";
import AddProgrammeDocument from "./pages/Programmes/AddProgramDocument";
import EditProgrammeDocument from "./pages/Programmes/EditProgrammeDocument";
import { SectionList } from "./pages/section/SectionList";
import ProgrammeReviews from "./pages/ProgramReviews/ProgrammeReviews";
import AddProgrammeReview from "./pages/ProgramReviews/AddProgramReview";
import EditProgrammeReview from "./pages/ProgramReviews/EditProgramReview";
import ProgrammeSectionList from "./pages/Sections/SectionList";
import LockedProgram from "./pages/Programmes/LockedProgramInfo";
import AddProgrammeSection from "./pages/Sections/AddProgrammeSection";
import EditProgrammeSection from "./pages/Sections/EditProgrammeSection";
import ProgrammeAutomatedGroups from "./pages/AutomatedGroups/ProgrammeAutomatedGroup";
import AddProgrammeAutoGroup from "./pages/AutomatedGroups/AddAutoGroup";
import EditProgrammeAutoGroup from "./pages/AutomatedGroups/EditAutoGroup";
import MembersList from "./pages/Members/MembersList";
import AddMember from "./pages/Members/AddMember";
import EditMember from "./pages/Members/EditMember";
import ConsultantList from "./pages/consultant/consultantList";
import AddConsultant from "./pages/consultant/AddConsultant";
import EditConsultant from "./pages/consultant/EditConsultant";
import ChangePassword from "./pages/Members/ChangePassword";
import ChangeConsultantPassword from "./pages/consultant/Changepassword";
import ConsultantRecordings from "./pages/consultant/ConsultantRecordings";
import ConsultantRecordingDetail from "./pages/consultant/RecordingDetail";
import MemberProfile from "./pages/Members/MemberProfile";
import MemberAllTransaction from "./pages/Members/AllTransactions";
import ViewBillingList from "./pages/Members/ViewBilling";
import BillingRequestDetail from "./pages/Members/BillingDetail";
import AddPaymentRequest from "./pages/Members/AddPaymentRequest";
import ManageProgrammeAccess from "./pages/Members/ManageProgrammeAccess";
import ConsultantGroupsList from "./pages/consultant/ConsultantGroups";
import ConsultantGroupDetail from "./pages/consultant/ConsultantGroupDetail";
import ConsultantPodsList from "./pages/consultant/PodsListing";
import MembersListConsultant from "./pages/consultant/MemberList";
import ConsultantGoalStatementList from "./pages/consultant/ConsultantGoalStatement";
import ConsultantPodsDetail from "./pages/consultant/PodsDetail";
import MemberGroupsList from "./pages/Members/MembersGroupList";
import MemberPersonalNote from "./pages/Members/MemberPersonalNote";
import MemberGoalstatementList from "./pages/Members/MemberGoalstatementList";
import PodsListMemberProfile from "./pages/Members/PodsListMemberProfile";
import ConsultantGoalstatementDetail from "./pages/consultant/ConsultantGoalstatementDetail";
import AddQuestions from "./pages/GoalStatement/AddQuestion";
import DetailQuestion from "./pages/GoalStatement/DetailQuestion";
import EditQuestions from "./pages/GoalStatement/EditQuestion";
import { QuestionsReply } from "./pages/Members/QuestionsReply";
import { AnswerHistory } from "./pages/Members/AnswerHistory";
import NinetyDayQuestion from "./pages/90DayQuestion/90DayQuestion";
import Recordings from "./pages/Recordings/Recordings";
import AddRecordings from "./pages/Recordings/AddRecordings";
import EditRecording from "./pages/Recordings/EditRecording";
import VaultCategory from "./pages/Vault/Vault";
import AddVaultCategory from "./pages/Vault/AddVaultCategory";
import EditVaultCategory from "./pages/Vault/EditVaultCategory";
import ProgrammeRecordingDetail from "./pages/Recordings/RecordingDetail";
import StudySession from "./pages/studySession/StudySession";
import AddStudySession from "./pages/studySession/AddStudySession";
import EditStudySession from "./pages/studySession/EditStudySession";
import StudySessionRecording from "./pages/studySession/StudySessionRecordings";
import AddStudySessionRecording from "./pages/studySession/AddStudySessionRecording";
import EditStudySessionRecording from "./pages/studySession/EditStudySessionRecording";
import QuotesList from "./pages/Quotes/Quotes";
import AddQuotes from "./pages/Quotes/AddQuotes";
import EditQuotes from "./pages/Quotes/EditQuotes";
import GratitudeDetail from "./pages/Gratitude/GratitudeDetail";
import EditGratitude from "./pages/Gratitude/EditGratitude";
import Affirmation from "./pages/Affirmation/Affirmation";
import AffirmationDetail from "./pages/Affirmation/AffirmationDetail";
import AddAffirmation from "./pages/Affirmation/AddAffirmation";
import EditAffirmation from "./pages/Affirmation/EditAffirmation";
import AffirmationCategories from "./pages/AffirmationCategories/AffirmationCategories";
import AddAffirmationCategory from "./pages/AffirmationCategories/AddAffirmationCategory";
import EditAffirmationCategory from "./pages/AffirmationCategories/EditAffirmationCategory";
import PaymentTransactionsList from "./pages/Payments/TransactionsList";
import StripeProductsList from "./pages/Payments/StripeProductsList";
import AddStripeProduct from "./pages/Payments/AddStripeProduct";
import EditStripeProduct from "./pages/Payments/EditStripeProduct";
import PaymentTemplateList from "./pages/PaymentTemplate/PaymentTemplateList";
import AddPaymentTemplate from "./pages/PaymentTemplate/AddPaymentTemplate";
import EditPaymentTemplate from "./pages/PaymentTemplate/EditPaymentTemplate";
import PaymentRequestList from "./pages/PaymentRequest/PaymentRequestList";
import AddPaymentRequests from "./pages/PaymentRequest/AddPaymentRequests";
import EditPaymentRequests from "./pages/PaymentRequest/EditPaymentRequests";
import PaymentRequestInfo from "./pages/PaymentRequest/PaymentRequestInfo";
import AddCalendarGroup from "./pages/CalendarGroups/AddCalendarGroups";
import CalendarGroupsList from "./pages/CalendarGroups/CalendarGroupList";
import EditCalendarGroup from "./pages/CalendarGroups/EditCalendarGroups";
import CalendarGroupDetail from "./pages/CalendarGroups/CalendarGroupDetail";
import CalendarPodsList from "./pages/CalendarPods/CalendarPodsList";
import AddPods from "./pages/CalendarPods/AddPods";
import AddCalendarPods from "./pages/CalendarPods/AddPods";
import EditCalendarPods from "./pages/CalendarPods/EditPods";
import CalendarPodsDetail from "./pages/CalendarPods/CalendarPodsDetail";
import AddAutomatedRoomUser from "./pages/CalendarPods/AddRoomUser";
import EditAutomatedRoomUser from "./pages/CalendarPods/EditRoomUser";
import Calenders from "./pages/Calender/Calender";
import ListEvents from "./pages/Calender/ListEvents";
import EventDetailList from "./pages/Calender/EventDetailList";
import DepartmentList from "./pages/Departments/DepartmentsList";
import AddDepartment from "./pages/Departments/AddDepartment";
import AddDepartments from "./pages/Departments/AddDepartment";
import EditDepartments from "./pages/Departments/EditDepartment";
import { SupportTicket } from "./pages/index";
import UpdateGeneralSetting from "./pages/ClientSetting/GeneralSetting";
import ClientDashboardSetting from "./pages/ClientSetting/ClientDashboard";
import SupportTicketDetail from "./pages/SupportTickets/DetailSupportTicket";
import UpdateContentSetting from "./pages/ClientSetting/ContentSetting";
import UpdateConsultantGeneralSetting from "./pages/ConsultantPortal/GeneralSetting";
import AddSendGrid from "./pages/sendGrid/SendGrid";
import AddCompaignSetting from "./pages/SiteSetting/CompaingSetting";
import AddStripeSetting from "./pages/SiteSetting/StripeSetting";
import EmailSetting from "./pages/SiteSetting/EmailSetting";
import DefaultSetting from "./pages/SiteSetting/DefaultSetting";
import EmailTemplates from "./pages/SiteSetting/EmailTemplates";
import EditEmailTemplate from "./pages/SiteSetting/EditEmailTemplate";
import { EmailTemplatePreview } from "./pages/SiteSetting/EmailTemplatePreview";
import ToDoTracker from "./pages/Members/ToDoTracker";
import WebsitePages from "./pages/ManageWebiste/Pages";
import AddPages from "./pages/ManageWebiste/AddPages";
import EditPages from "./pages/ManageWebiste/EditPages";
import UpdatePageContent from "./pages/ManageWebiste/UpdatePageContent";
import WebsiteEventList from "./pages/ManageWebiste/WebsiteEventList";
import AddWebsiteEvent from "./pages/ManageWebiste/AddWebsiteEvent";
import EditWebsiteEvent from "./pages/ManageWebiste/EditWebsiteEvent";
import WebsiteTestimonials from "./pages/WebsiteTestimonials/WebsiteTestimonial";
import AddWebsiteTestimonial from "./DAL/Testimonial/AddWebsiteTestimonial";
import EditWebsiteTestimonial from "./DAL/Testimonial/EditWebsiteTestimonial";
import PaymentPlansList from "./pages/PaymentPlans/PaymentPlansList";
import AddPaymentPlan from "./pages/PaymentPlans/AddpaymentPlan";
import EditPaymentPlan from "./pages/PaymentPlans/EditpaymentPlan";
import PodCastList from "./pages/WebsitePodCasts/PodCastList";
import GeneralPaymentPlansList from "./pages/PaymentPlanGeneral/PaymentPlansList";
import EditProfile from "./pages/EditProfile/EditProfile";
import AddGeneralPaymentPlan from "./pages/PaymentPlanGeneral/AddGeneralpaymentPlan";
import EditGeneralPaymentPlan from "./pages/PaymentPlanGeneral/EditGeneralpaymentPlan";
import SalePageTestimonials from "./pages/salePageTestimonial/SalePageTestimonial";
import AddGeneralTestimonial from "./pages/salePageTestimonial/AddGeneralTestimonial";
import EditSalePageTestimonial from "./pages/salePageTestimonial/EditGeneralTestimonial";
import ProgrammeCategoriesList from "./pages/ProgrammeCategories/ProgrammeCategoriesList";
import EventSubscribers from "./pages/Members/EventSubscriber";
import GeneralFaqList from "./pages/FAQ/GeneralFaqList";
import AddFAQ from "./pages/FAQ/AddFaq";
import EditFAQ from "./pages/FAQ/EditFaq";
import WebPageFaqs from "./pages/WebPageFaqs/WebPageFaqs";
import AddWebPageFaq from "./pages/WebPageFaqs/AddWebPageFaq";
import EditWebPageFaq from "./pages/WebPageFaqs/EditWebPageFaq";
import PodcastsList from "./pages/Podcasts/PodCastList";
import AddPodCast from "./pages/Podcasts/AddPodCast";
import EditPodCast from "./pages/Podcasts/EditPodCast";
import WebsiteProgrammesList from "./pages/WebsiteProgrammes/WebsiteProgrammesList";
import AddWebsiteProgramme from "./pages/WebsiteProgrammes/AddWebsiteProgramme";
import EditWebsiteProgramme from "./pages/WebsiteProgrammes/EditWebsiteProgramme";
import AddProgrammeCategories from "./pages/ProgrammeCategories/AddProgrammeCategoies";
import EditProgrammeCategories from "./pages/ProgrammeCategories/EditProgrammeCategoies";
import GeneralTeamList from "./pages/Team/TeamList";
import AddTeam from "./pages/Team/AddTeam";
import EditTeam from "./pages/Team/EditTeam";
import WhatsIncludeList from "./pages/WhatsInclude/WhatsIncludeList";
import AddWhatsInclude from "./pages/WhatsInclude/AddWhatsInclude";
import EditWhatsInclude from "./pages/WhatsInclude/EditWhatsInclude";
import BusinessStrategyList from "./pages/BusinessStrategy/BusinessStrategyList";
import AddBusinessStrategy from "./pages/BusinessStrategy/AddBusinessStrategy";
import EditBusinessStrategy from "./pages/BusinessStrategy/EditBusinessStrategy";
import BusinessPartnerList from "./pages/business Partner/BusinessPartnerList";
import AddBusinessPartner from "./pages/business Partner/AddBusinessPartner";
import EditBusinessPartner from "./pages/business Partner/EditBusinessPartner";
import ServicesList from "./pages/Services/ServicesList";
import AddService from "./pages/Services/AddService";
import EditService from "./pages/Services/EditService";
import BannerSliderList from "./pages/BannerSlider/BannerSliderList";
import AddBannerSlider from "./pages/BannerSlider/AddBannerSlider";
import EditBannerSlider from "./pages/BannerSlider/EditBannerSlider";
import UpdatePageContentTest from "./pages/ManageWebiste/UpdatePageContentTest";
import BookCategoryList from "./pages/BookCategory/BookCategoryList";
import AddBookCategory from "./pages/BookCategory/AddBookCategory";
import EditBookCategory from "./pages/BookCategory/EditBookCategory";
import BookList from "./pages/Books/BooksList";
import AddBook from "./pages/Books/AddBook";
import EditBook from "./pages/Books/EditBook";
import { MenuItems } from "./pages/MenuItems/MenuItems";
import AddOrUpdatePage from "./pages/ManageWebiste/AddOrUpdatePage";
import MenuList from "./pages/MenuItems/MenuList";
import SuccessList from "./pages/Success/SuccessList";
import AddSuccess from "./pages/Success/AddSuccess";
import NewsLetterList from "./pages/NewsLetter/NewsLetterList";
import NewsLetterCsv from "./pages/NewsLetter/NewsLetter";
import SocialSharingCenter from "./pages/ManageWebiste/soicalSharingCenter";
import ProgrammeBulkAccess from "./components/ProgrammBulkAccess/ProgrammeBulkAccess";
import BlogsList from "./pages/Blogs/BlogsList";
import AddBlog from "./pages/Blogs/AddBlog";
import EditBlogs from "./pages/Blogs/EditBlogs";
import InvoiceSetting from "./pages/InvoiceSetting/InvoiceSetting";
import NewBooks from "./pages/NewBooks/NewBooks";
import AddBooks from "./pages/NewBooks/AddBooks";
import EditBooks from "./pages/NewBooks/EditBooks";
import DetailBooks from "./pages/NewBooks/DetailBooks";
import BookPreview from "./pages/NewBooks/BookPreview";

// ----------------------------------------------------------------------
const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};
export default function Router() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<DashboardApp />} />
        <Route path="/programmes" element={<Programmes />} />
        <Route
          path="/programmes/programmes-detail/:id"
          element={<ProgrammesDetail />}
        />
        <Route path="/programmes/add-programme" element={<AddProgramme />} />
        <Route path="/programmes/edit-programme" element={<EditProgramme />} />
        <Route
          path="/programmes/lessons-listing/:slug"
          element={<LessonListing />}
        />
        <Route
          path="/programmes/lessons-detail/:id"
          element={<LessonsDetail />}
        />
        <Route path="/programmes/add-lessons" element={<AddLesson />} />
        <Route path="/programmes/edit-lessons/:id" element={<EditLesson />} />
        <Route
          path="/programmes/lessons/recordings/:slug"
          element={<LessonRecordingListing />}
        />
        <Route
          path="/programmes/lesson-recording-detail/:slug"
          element={<LessonRecordingDetail />}
        />
        <Route
          path="/programmes/add-lesson-recording/:slug"
          element={<AddLessonRecording />}
        />
        <Route
          path="/programmes/edit-lessons-recording/:slug"
          element={<EditLessonRecording />}
        />
        <Route
          path="/programmes/lesson/lesson-doc/:slug"
          element={<ResourcesCard />}
        />
        <Route
          path="/programmes/lessons/add-lesson-document/:slug"
          element={<AddLessonDocument />}
        />
        <Route
          path="/programmes/lessons/edit-lesson-document/:slug"
          element={<EditLessonDocument />}
        />
        <Route path="/programmes/:type/:id" element={<ProgrammeBulkAccess />} />
        {/* programms Doc routes */}
        {/* business-strategy */}
        <Route path="/business-strategy" element={<BusinessStrategyList />} />
        <Route
          path="/business-strategy/add-business-strategy"
          element={<AddBusinessStrategy />}
        />
        <Route
          path="/business-strategy/:id"
          element={<EditBusinessStrategy />}
        />
        {/* business-strategy ends */}
        {/* business-strategy sale page */}
        <Route
          path="/website-pages/business-strategy/:page_id"
          element={<BusinessStrategyList />}
        />
        <Route
          path="/website-pages/business-strategy/add-business-strategy/:page_id"
          element={<AddBusinessStrategy />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy/edit-business-strategy/:id"
          element={<EditBusinessStrategy />}
        />
        {/* business-strategy sale page ends */}
        {/* services  */}
        <Route path="/website-services" element={<ServicesList />} />
        <Route path="/website-services/add-service" element={<AddService />} />
        <Route
          path="/website-services/edit-service/:id"
          element={<EditService />}
        />
        {/* services end */}
        {/* general services  */}
        <Route
          path="/website-pages/service/:page_id"
          element={<ServicesList />}
        />
        <Route
          path="/website-pages/service/add-service/:page_id"
          element={<AddService />}
        />
        <Route
          path="/website-pages/service/:page_id/edit-service/:id"
          element={<EditService />}
        />
        {/* general services end */}
        {/* blogs */}
        <Route
          path="/website-pages/website-blogs-list/:page_id"
          element={<BlogsList />}
        />
        <Route path="/website-blogs" element={<BlogsList />} />
        <Route
          path="/website-pages/blogs/add-blogs/:page_id"
          element={<AddBlog />}
        />
        <Route path="/website-blogs/edit-blogs/:id" element={<EditBlogs />} />
        <Route path="/website-blogs/add-blogs" element={<AddBlog />} />
        <Route
          path="/website-pages/blogs/:page_id/edit-blogs/:id"
          element={<EditBlogs />}
        />
        {/* Banner slider  */}
        <Route path="/website-banner-slider" element={<BannerSliderList />} />
        <Route
          path="/website-banner-slider/add-banner-slider"
          element={<AddBannerSlider />}
        />
        <Route
          path="/website-banner-slider/edit-banner-slider/:id"
          element={<EditBannerSlider />}
        />
        {/* Banner slider end */}
        {/* Banner slider sale page */}
        <Route
          path="/website-pages/banner-slider/:page_id"
          element={<BannerSliderList />}
        />
        <Route
          path="/website-pages/banner-slider/add-banner-slider/:page_id"
          element={<AddBannerSlider />}
        />
        <Route
          path="/website-pages/banner-slider/:page_id/edit-banner-slider/:id"
          element={<EditBannerSlider />}
        />
        {/* Banner slider sale page end */}
        {/* PodCastList */}
        <Route path="/pod-casts" element={<PodCastList />} />
        {/* PodCastList */}
        <Route
          path="/website-categories/website-programmes/:id"
          element={<WebsiteProgrammesList />}
        />
        <Route
          path="/website-pages/website-categories/:page_id/website-programmes/:category_id"
          element={<WebsiteProgrammesList />}
        />
        <Route path="/website-programmes" element={<WebsiteProgrammesList />} />
        <Route
          path="/website-pages/website-categories/:page_id/website-programmes/add-programmes/:category_id"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/website-categories/website-programmes/add-programmes"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/website-programmes/add-programmes"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/website-pages/website-categories/website-programmes/edit-programmes/:page_id"
          element={<EditWebsiteProgramme />}
        />
        <Route
          path="/website-categories/website-programmes/edit-programmes/:id"
          element={<EditWebsiteProgramme />}
        />
        <Route
          path="/website-programmes/edit-programmes/:id"
          element={<EditWebsiteProgramme />}
        />
        <Route
          path="/website-categories"
          element={<ProgrammeCategoriesList />}
        />
        {/* book category general  */}
        {/* ----------------------------------------------------------------------------- */}
        <Route path="/book-categories" element={<BookCategoryList />} />
        <Route
          path="/book-categories/add-category"
          element={<AddBookCategory />}
        />
        <Route
          path="/book-categories/edit-category/:id"
          element={<EditBookCategory />}
        />
        {/* book inside general book category */}
        <Route path="/book-categories/books" element={<BookCategoryList />} />
        <Route path="/book-categories/add-book" element={<AddBookCategory />} />
        <Route
          path="/book-categories/edit-book/:id"
          element={<EditBookCategory />}
        />
        {/* book inside general book category end*/}
        {/* book category general end */}
        {/* Book  */}
        <Route
          path="/book-categories/book/:category_id"
          element={<BookList />}
        />
        <Route
          path="/book-categories/book/:category_id/add-book"
          element={<AddBook />}
        />
        <Route
          path="/book-categories/book/:category_id/edit-book/:id"
          element={<EditBook />}
        />
        <Route
          path="/website-pages/book-categories/:category_slug/book/:category_id/:page_id"
          element={<BookList />}
        />
        <Route
          path="/website-pages/book-categories/:category_slug/book/:category_id/add-book/:page_id"
          element={<AddBook />}
        />
        <Route
          path="/website-pages/book-categories/:category_slug/book/:category_id/edit-book/:page_id"
          element={<EditBook />}
        />
        {/* Book end */}
        {/* book category against sale page  */}
        <Route
          path="/website-pages/book-categories/:page_id"
          element={<BookCategoryList />}
        />
        <Route
          path="/website-pages/book-categories/add-category/:page_id"
          element={<AddBookCategory />}
        />
        <Route
          path="/website-pages/book-category/:page_id/edit-book-category/:id"
          element={<EditBookCategory />}
        />
        {/* book category against sale page end */}
        {/* program category  */}
        <Route
          path="/website-categories/add-category"
          element={<AddProgrammeCategories />}
        />
        <Route
          path="/website-categories/edit-category/:id"
          element={<EditProgrammeCategories />}
        />
        <Route
          path="/website-pages/website-categories/:page_id"
          element={<ProgrammeCategoriesList />}
        />
        <Route
          path="/website-pages/website-categories/add-category/:page_id"
          element={<AddProgrammeCategories />}
        />
        {/* <Route path="/news-letter" element={<NewsLetterList />} /> */}
        <Route path="/news-letter" element={<NewsLetterCsv />} />
        <Route
          path="/website-pages/:page_id/website-categories/edit-category/:id"
          element={<EditProgrammeCategories />}
        />
        {/* program category  */}
        <Route
          path="/website-categories/add-category"
          element={<AddProgrammeCategories />}
        />
        <Route
          path="/website-categories/edit-category/:id"
          element={<EditProgrammeCategories />}
        />
        <Route
          path="/website-general-payment-plans"
          element={<GeneralPaymentPlansList />}
        />
        <Route
          path="/website-general-payment-plans/add-payment-plan/"
          element={<AddGeneralPaymentPlan />}
        />
        <Route
          path="/website-general-payment-plans/edit-payment-plan/:id"
          element={<EditGeneralPaymentPlan />}
        />
        <Route
          path="/programmes/programm-document/:id"
          element={<ProgrammeDocumentList />}
        />
        <Route
          path="/programmes/add-programme-document/:slug"
          element={<AddProgrammeDocument />}
        />
        <Route
          path="/programmes/edit-programme-document/:slug"
          element={<EditProgrammeDocument />}
        />
        {/* programms Doc routes end */}
        {/* program > section */}
        <Route path="/programmes/sections/:slug" element={<SectionList />} />
        {/* program > section end */}
        {/* Programme reviews */}
        <Route
          path="/programmes/reviews/:slug"
          element={<ProgrammeReviews />}
        />
        <Route
          path="/programmes/add-reviews/:slug"
          element={<AddProgrammeReview />}
        />
        <Route
          path="/programmes/edit-review/:slug"
          element={<EditProgrammeReview />}
        />
        {/* Programme reviews end*/}
        {/* program section  */}
        <Route
          path="/programmes/section/:slug"
          element={<ProgrammeSectionList />}
        />
        <Route
          path="/programmes/locked-programme/:slug"
          element={<LockedProgram />}
        />
        <Route
          path="/programmes/add-section/:slug"
          element={<AddProgrammeSection />}
        />
        <Route
          path="/programmes/edit-section/:slug"
          element={<EditProgrammeSection />}
        />
        {/* program section end */}
        {/* ProgrammeAutomatedGroups */}
        <Route
          path="/programmes/automated-group/:slug"
          element={<ProgrammeAutomatedGroups />}
        />
        <Route
          path="/programmes/add-automated-group/:slug"
          element={<AddProgrammeAutoGroup />}
        />
        <Route
          path="/programmes/edit-automated-group/:slug"
          element={<EditProgrammeAutoGroup />}
        />
        {/* ProgrammeAutomatedGroups end  */}
        {/* Member */}
        <Route path="/member" element={<MembersList />} />
        <Route path="/member/add-member" element={<AddMember />} />
        <Route path="/member/edit-member/:id" element={<EditMember />} />
        <Route path="/member/member-profile/:id" element={<MemberProfile />} />
        <Route
          path="/member/member-groups/:id"
          element={<MemberGroupsList />}
        />
        <Route
          path="/consultant/member-groups/:id"
          element={<MemberGroupsList />}
        />
        <Route
          path="/member/member-personal-notes/:id"
          element={<MemberPersonalNote />}
        />
        <Route
          path="/member/member-goal-statement/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member/goal-statement-Question-reply/:id"
          element={<QuestionsReply />}
        />
        <Route
          path="/member/event-subscriber/:id"
          element={<EventSubscribers />}
        />
        <Route
          path="/member/goal-statement-Question-history/:id"
          element={<AnswerHistory />}
        />
        <Route
          path="/member/programme-access/:id"
          element={<ManageProgrammeAccess />}
        />
        <Route
          path="/member/add-payment-request/:id"
          element={<AddPaymentRequest />}
        />
        <Route
          path="/member/billing-detail"
          element={<BillingRequestDetail />}
        />
        <Route
          path="/member/all-transaction/:id"
          element={<MemberAllTransaction />}
        />
        <Route
          path="/member/view-billing-list/:id"
          element={<ViewBillingList />}
        />
        <Route
          path="/member/change-password/:id"
          element={<ChangePassword />}
        />
        <Route path="/member/member-pods" element={<PodsListMemberProfile />} />
        <Route path="/member/to-do-tracker/:id" element={<ToDoTracker />} />
        {/* member end  */}
        {/* consultant   */}
        <Route path="/consultant" element={<ConsultantList />} />
        <Route
          path="/consultant/pods-list/:id"
          element={<ConsultantPodsList />}
        />
        <Route
          path="/consultant/member-list/:id"
          element={<MembersListConsultant />}
        />
        <Route path="/consultant/add-consultant" element={<AddConsultant />} />
        <Route
          path="/consultant/member-profile/:id"
          element={<MemberProfile />}
        />
        <Route
          path="/consultant/groups/:id"
          element={<ConsultantGroupsList />}
        />
        <Route
          path="/consultant/goal-statement-list/:id"
          element={<ConsultantGoalStatementList />}
        />
        <Route
          path="/consultant/pods-detail/:id"
          element={<ConsultantPodsDetail />}
        />
        <Route
          path="/consultant/view-group-detail/:id"
          element={<ConsultantGroupDetail />}
        />
        <Route
          path="/consultant/goal-statement-detail/:id"
          element={<ConsultantGoalstatementDetail />}
        />
        <Route
          path="/consultant/recording-detail/:id"
          element={<ConsultantRecordingDetail />}
        />
        <Route
          path="/consultant/edit-consultant/:id"
          element={<EditConsultant />}
        />
        <Route
          path="/consultant/change-password/:id"
          element={<ChangeConsultantPassword />}
        />
        <Route
          path="/consultant/recordings/:id"
          element={<ConsultantRecordings />}
        />
        {/* consultant end  */}
        {/* /goal-statement start */}
        <Route path="/goal-statement" element={<GoalStatement />} />
        <Route path="/goal-statement/add-question" element={<AddQuestions />} />
        <Route
          path="/goal-statement/detail-question/:id"
          element={<DetailQuestion />}
        />
        <Route
          path="/goal-statement/edit-question/:slug"
          element={<EditQuestions />}
        />
        {/* /goal-statement end */}
        {/* /website-pages */}
        <Route
          path="/website-pages/whats-include/:id"
          element={<WhatsIncludeList />}
        />
        <Route
          path="/website-pages/whats-include/add-whats-include/:id"
          element={<AddWhatsInclude />}
        />
        <Route
          path="/website-pages/whats-include/edit-whats-include/:id"
          element={<EditWhatsInclude />}
        />
        <Route path="/website-pages" element={<WebsitePages />} />
        <Route path="/website-team" element={<GeneralTeamList />} />
        <Route path="/website-team/add-team" element={<AddTeam />} />
        <Route path="/website-team/edit-team/:id" element={<EditTeam />} />
        <Route path="/website-faq" element={<GeneralFaqList />} />
        <Route path="/website-faq/add-faq" element={<AddFAQ />} />
        <Route path="/website-faq/edit-faq/:id" element={<EditFAQ />} />
        <Route
          path="/website-pages/payment-plans/:id"
          element={<PaymentPlansList />}
        />
        <Route
          path="/website-pages/payment-plans/add-payment-plan/:id"
          element={<AddPaymentPlan />}
        />
        <Route
          path="/website-pages/payment-plans/edit-payment-plan/:id"
          element={<EditPaymentPlan />}
        />
        <Route path="/website-events" element={<WebsiteEventList />} />
        <Route
          path="/website-pages/sale-page-event/:id"
          element={<WebsiteEventList />}
        />
        <Route
          path="/website-pages/:page_id/add-sale-page-event"
          element={<AddWebsiteEvent />}
        />
        <Route
          path="/website-pages/:page_id/edit-sale-page-event/:id"
          element={<EditWebsiteEvent />}
        />
        <Route
          path="/website-events/add-website-event"
          element={<AddWebsiteEvent />}
        />
        <Route
          path="/website-events/edit-website-event/:id"
          element={<EditWebsiteEvent />}
        />
        <Route path="/website-pages/add-page" element={<AddPages />} />
        <Route
          path="/website-pages/edit-page/:page_slug"
          element={<EditPages />}
        />
        <Route
          path="/template-pages/socail-sharing-setting/:page_slug"
          element={<SocialSharingCenter />}
        />
        <Route
          path="/website-pages/update-page-content/:id"
          element={<UpdatePageContent />}
        />
        <Route
          path="/website-pages/success/:page_id"
          element={<SuccessList />}
        />
        <Route path="/website-pages/blogs/:page_id" element={<SuccessList />} />
        <Route
          path="/website-pages/add-success/:page_id"
          element={<AddSuccess />}
        />
        <Route
          path="/website-pages/:page_id/edit-success/:id"
          element={<AddSuccess />}
        />
        {/* <Route
          path="/website-pages/update-page-content/:id"
          element={<UpdatePageContentTest />}
        /> */}
        <Route path="/website-testimonials" element={<WebsiteTestimonials />} />
        <Route
          path="/website-testimonials/add-website-testimonial"
          element={<AddWebsiteTestimonial />}
        />
        <Route
          path="/website-testimonials/edit-website-testimonial/:id"
          element={<EditWebsiteTestimonial />}
        />
        <Route
          path="/website-testimonials/sale-testimonial/:id"
          element={<SalePageTestimonials />}
        />
        <Route
          path="/website-testimonials/sale-testimonial/add-sale-testimonial/:id"
          element={<AddGeneralTestimonial />}
        />
        <Route
          path="/website-testimonials/sale-testimonial/:page_id/edit-sale-testimonial/:id"
          element={<EditSalePageTestimonial />}
        />
        <Route
          path="/website-pages/sale-testimonial/:id"
          element={<SalePageTestimonials />}
        />
        <Route
          path="/website-pages/sale-testimonial/add-sale-testimonial/:id"
          element={<AddGeneralTestimonial />}
        />
        <Route
          path="/website-pages/sale-testimonial/:page_id/edit-sale-testimonial/:id"
          element={<EditSalePageTestimonial />}
        />
        {/* /90-day-questions */}
        <Route path="/90-day-questions" element={<NinetyDayQuestion />} />
        {/* /90-day-questions end */}
        {/* ProgrammeCategories */}
        <Route
          path="/websiteProgrammeCategories"
          element={<ProgrammeCategoriesList />}
        />
        {/* recording /recordings */}
        <Route path="/recording-list" element={<Recordings />} />
        <Route
          path="/recording-list/recording-detail"
          element={<ProgrammeRecordingDetail />}
        />
        <Route
          path="/recording-list/add-recording"
          element={<AddRecordings />}
        />
        <Route
          path="/recording-list/edit-recording"
          element={<EditRecording />}
        />
        {/* recording end */}
        {/* vault VaultCategory */}
        <Route path="/vault" element={<VaultCategory />} />
        <Route path="/vault/add-vault" element={<AddVaultCategory />} />
        <Route path="/vault/edit-vault" element={<EditVaultCategory />} />
        {/* vault end */}
        {/* Sessions end */}
        <Route path="/sessions" element={<StudySession />} />
        <Route path="/sessions/add-session" element={<AddStudySession />} />
        <Route path="/sessions/edit-session" element={<EditStudySession />} />
        <Route path="/session-recording" element={<StudySessionRecording />} />
        <Route
          path="/session-recording/add-study-session-recording"
          element={<AddStudySessionRecording />}
        />
        <Route
          path="/sessions-recording/edit-study-session-recording"
          element={<EditStudySessionRecording />}
        />
        {/* Sessions */}
        {/* QuotesList */}
        <Route path="/quotesList" element={<QuotesList />} />
        <Route path="/quotesList/add-quotes" element={<AddQuotes />} />
        <Route path="/quotesList/edit-quotes" element={<EditQuotes />} />
        {/* QuotesList end */}
        {/*  general team  */}
        <Route path="/website-team" element={<GeneralTeamList />} />
        <Route path="/website-team/:page_id/add-team" element={<AddTeam />} />
        <Route
          path="/website-team/:page_id/edit-team/:id"
          element={<EditTeam />}
        />
        {/*  general team end  */}
        {/* sale page Team */}
        <Route
          path="/website-pages/team/:page_id"
          element={<GeneralTeamList />}
        />
        <Route path="/website-pages/add-team" element={<AddTeam />} />
        <Route path="/website-pages/edit-team/:id" element={<EditTeam />} />
        {/* sale page Team end */}
        {/* BusinessPartnerList */}
        <Route path="/business-partner" element={<BusinessPartnerList />} />
        <Route
          path="/business-partner/add-business-partner"
          element={<AddBusinessPartner />}
        />
        <Route
          path="/business-partner/edit-business-partner/:id"
          element={<EditBusinessPartner />}
        />
        <Route
          path="/website-pages/business-partner/:page_id"
          element={<BusinessPartnerList />}
        />
        <Route
          path="/website-pages/business-partner/add-business-partner/:page_id"
          element={<AddBusinessPartner />}
        />
        <Route
          path="/website-pages/business-partner/:page_id/edit-business-partner/:id"
          element={<EditBusinessPartner />}
        />
        {/* BusinessPartnerList end */}
        {/* gratitude  */}
        <Route path="/gratitude" element={<Gratitude />} />
        <Route path="/gratitude/add-gratitude" element={<AddGratitude />} />
        <Route path="/gratitude/edit-gratitude" element={<EditGratitude />} />
        <Route
          path="/gratitude/gratitude-detail/:id"
          element={<GratitudeDetail />}
        />
        {/* gratitude end */}
        {/* Affirmation  */}
        <Route path="/affirmation" element={<Affirmation />} />
        <Route
          path="/affirmation/add-affirmation"
          element={<AddAffirmation />}
        />
        <Route
          path="/affirmation/edit-affirmation"
          element={<EditAffirmation />}
        />
        <Route
          path="/affirmation/affirmation-detail/:id"
          element={<AffirmationDetail />}
        />
        {/* Affirmation end */}
        {/* /affirmation-categories */}
        <Route
          path="/affirmations-categories"
          element={<AffirmationCategories />}
        />
        <Route
          path="/affirmations-categories/add-category"
          element={<AddAffirmationCategory />}
        />
        <Route
          path="/affirmations-categories/edit-category"
          element={<EditAffirmationCategory />}
        />
        {/* /affirmation-categories end */}
        {/* PaymentTransactions */}
        <Route
          path="/payment-transactions"
          element={<PaymentTransactionsList />}
        />
        <Route path="/stripe-products" element={<StripeProductsList />} />
        <Route
          path="/stripe-products/add-product"
          element={<AddStripeProduct />}
        />
        <Route
          path="/stripe-products/edit-product/:id"
          element={<EditStripeProduct />}
        />
        {/* PaymentTransactions end */}
        {/* PaymentTemplateList  */}
        <Route path="/payment-template" element={<PaymentTemplateList />} />
        <Route
          path="/payment-template/add-template"
          element={<AddPaymentTemplate />}
        />
        <Route
          path="/payment-template/edit-template/:id"
          element={<EditPaymentTemplate />}
        />
        {/* PaymentTemplateList end  */}
        {/* PaymentRequest   */}
        <Route path="/payment-request" element={<PaymentRequestList />} />
        <Route
          path="/payment-request/add-payment-request"
          element={<AddPaymentRequests />}
        />
        <Route
          path="/payment-request/payment-request-detail"
          element={<PaymentRequestInfo />}
        />
        <Route
          path="/payment-request/edit-payment-request/:id"
          element={<EditPaymentRequests />}
        />
        {/* PaymentRequest end   */}
        {/* Calendar    AddCalendarGroup*/}
        <Route path="/calendar-groups" element={<CalendarGroupsList />} />
        <Route
          path="/calendar-groups/group-detail/:id"
          element={<CalendarGroupDetail />}
        />
        <Route
          path="/calendar-groups/add-group"
          element={<AddCalendarGroup />}
        />
        <Route
          path="/calendar-groups/edit-group/:id"
          element={<EditCalendarGroup />}
        />
        {/*Calendar end  */}
        {/*DepartmentList   */}
        <Route path="/departments" element={<DepartmentList />} />
        <Route
          path="/departments/add-department"
          element={<AddDepartments />}
        />
        <Route
          path="/departments/edit-department/:id"
          element={<EditDepartments />}
        />
        {/*DepartmentList end  */}
        {/*SupportTicket   */}
        <Route path="/support-ticket" element={<SupportTicket />} />
        <Route
          path="/support-ticket/detail/:id"
          element={<SupportTicketDetail />}
        />
        <Route path="/trash-support-ticket" element={<SupportTicket />} />
        {/*SupportTicket end  */}
        {/*Calendarpods  */}
        <Route path="/calendar-pods" element={<CalendarPodsList />} />
        <Route path="/calendarEvents" element={<Calenders />} />
        <Route path="/calendarEvents/event-list" element={<ListEvents />} />
        <Route path="/calender/event-list" element={<ListEvents />} />
        <Route path="/calender/event-detail" element={<EventDetailList />} />
        <Route
          path="/calendarEvents/event-detail"
          element={<EventDetailList />}
        />
        <Route path="/calendar-pods/add-pod" element={<AddCalendarPods />} />
        <Route
          path="/calendar-pods/edit-pod/:id"
          element={<EditCalendarPods />}
        />
        <Route
          path="/calendar-pods/pods-detail/:id"
          element={<CalendarPodsDetail />}
        />
        <Route
          path="/calendar-pods/add-room-user/:id"
          element={<AddAutomatedRoomUser />}
        />
        <Route
          path="/calendar-pods/edit-room-user/:id"
          element={<EditAutomatedRoomUser />}
        />
        {/*Calendarpods  end*/}
        {/* /general-setting */}
        <Route path="/general-setting" element={<UpdateGeneralSetting />} />
        <Route path="/client-dashboard" element={<ClientDashboardSetting />} />
        <Route path="/content-setting" element={<UpdateContentSetting />} />
        {/* /website-podcasts */}
        <Route path="/website-podcasts" element={<PodcastsList />} />
        <Route path="/website-podcasts/add-podcast" element={<AddPodCast />} />
        <Route
          path="/website-podcasts/edit-podcast/:id"
          element={<EditPodCast />}
        />
        {/* ConsultantGeneralSetting */}
        <Route path="/send-grid" element={<AddSendGrid />} />
        <Route path="/add-stripe" element={<AddStripeSetting />} />
        <Route path="/email-setting" element={<EmailSetting />} />
        <Route path="/invoice-setting" element={<InvoiceSetting />} />
        <Route path="/default-setting" element={<DefaultSetting />} />
        <Route path="/email-templates" element={<EmailTemplates />} />
        <Route path="/add-compaign" element={<AddCompaignSetting />} />
        <Route
          path="/email-templates/preview/:id"
          element={<EmailTemplatePreview />}
        />
        <Route
          path="/email-templates/edit-temail-emplate/:id"
          element={<EditEmailTemplate />}
        />
        {/* <Route path="/website-team/add-team" element={<AddTeam />} /> */}
        {/* <Route path="/website-team/edit-team/:id" element={<EditTeam />} /> */}
        <Route path="/website-faq" element={<GeneralFaqList />} />
        <Route path="/website-faq/add-faq" element={<AddFAQ />} />
        <Route path="/website-faq/edit-faq/:id" element={<EditFAQ />} />
        <Route
          path="/website-pages/:page_id/website-faq"
          element={<WebPageFaqs />}
        />
        <Route
          path="/website-pages/:page_id/website-faq/add-faq"
          element={<AddWebPageFaq />}
        />
        <Route
          path="website-pages/:page_id/website-faq/edit-faq/:id"
          element={<EditWebPageFaq />}
        />
        <Route
          path="/consultants-general-setting"
          element={<UpdateConsultantGeneralSetting />}
        />
        <Route
          path="/programmes/programm_recording_detail/:id"
          element={<ProgrammRecordingDetail />}
        />
        <Route path="/goal_statement" element={<GoalStatement />} />
        <Route path="/profile" element={<EditProfile />} />
        <Route path="/pods" element={<Pods />} />
        <Route path="/affirmations" element={<Affirmations />} />
        <Route
          path="/affirmations/affirmationList"
          element={<AffirmationList />}
        />
        <Route path="/gratitude" element={<Gratitude />} />
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/memories" element={<Memories />} />
        <Route path="/memories/memoriesdetails" element={<MemoriesDetails />} />
        <Route path="/daily_activities" element={<DailyActivities />} />
        <Route path="/calender" element={<Calender />} />
        <Route path="/menu" element={<MenuList />} />
        {/* <Route path="/menu" element={<MenuItems />} /> */}
        <Route path="/menu/add-menu" element={<MenuItems />} />
        <Route path="/menu/:id" element={<MenuItems />} />
        {/* Books */}
        <Route path="/books" element={<NewBooks />} />
        <Route path="/books/add-book" element={<AddBooks />} />
        <Route path="/books/edit-book/:id" element={<EditBooks />} />
        <Route path="/books/preview-book/:file_url" element={<BookPreview />} />
        <Route path="/books/detail-book/:id" element={<DetailBooks />} />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      {/* <PublicRouteWithLayout component={Login} exact layout={LogoOnlyLayout} path="/login" />
      <PublicRouteWithLayout component={Register} exact layout={LogoOnlyLayout} path="/register" /> */}
      {/* --------------------------------------- Dashboard --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={DashboardApp}
        exact
        layout={DashboardLayout}
        path="/dashboard/app"
      /> */}
      {/* --------------------------------------- Change Password --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={Products}
        exact
        layout={DashboardLayout}
        path="/dashboard/products"
      /> */}
    </Routes>
  );
}
//   return useRoutes([
//     {
//       path: '/dashboard',
//       element: <DashboardLayout />,
//       children: [
//         { element: <Navigate to="/dashboard/app" replace /> },
//         { path: 'app', element: <DashboardApp /> },
//         { path: 'user', element: <User /> },
//         { path: 'products', element: <Products /> },
//         { path: 'blog', element: <Blog /> }
//       ]
//     },
//     {
//       path: '/',
//       element: <LogoOnlyLayout />,
//       children: [
//         { path: 'login', element: <Login /> },
//         { path: 'register', element: <Register /> },
//         { path: '404', element: <NotFound /> },
//         { path: '/', element: <Navigate to="/dashboard" /> },
//         { path: '*', element: <Navigate to="/404" /> }
//       ]
//     },
//     { path: '*', element: <Navigate to="/404" replace /> }
//   ]);
// }
