import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { programmeListing } from "src/DAL/Programme/Programme";
import { s3baseUrl } from "../../config/config";
import { Avatar, Chip, CircularProgress, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import { programmeAccessApi } from "src/DAL/member/member";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { newsLetterListApi } from "src/DAL/newsLetter/newsLetterApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function NewsLetterCsv() {
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [program, setProgram] = React.useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [expiryDate, setExpiryDate] = React.useState([]);
  const [date, setDate] = React.useState("");

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }

  const programsList = async () => {
    setIsLoading(true);
    const result = await newsLetterListApi();
    if (result.code == 200) {
      setProgram(result.news_letter);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let programme = [];
    let programVal = {};
    selectionModel.forEach((program) => {
      programVal = {
        program_id: program,
      };
      programme.push(programVal);
    });
    let programObject = {
      program: programme,
    };
    var newArray = programme.concat(expiryDate);
    expiryDate.map((value, i) => {
      console.log(value, "vallllll");
    });

    console.log(newArray, "newArray");

    const result = await programmeAccessApi(params.id, programObject);
    if (result.code == 200) {
      setIsLoading(false);
      enqueueSnackbar("Member added against programme successfully", {
        variant: "success",
      });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeDate = (date, id) => {
    // setDate(date);
    let programVal = {
      program_id: id._id,
      expiry_date: moment(date).format("MM DD YYYY"),
    };
    expiryDate.push(programVal);
  };

  const addDays = (days) => {
    var result = new Date();
    result.setDate(result.getDate() + days);
    return result;
  };

  const getFormattedData = () => {
    const list = program.map((item, i) => {
      return {
        id: i + 1,
        name: item?.first_name + " " + item?.last_name,
        email: item?.email,
        contact: item?.contact_number,
        status: item?.status,
        object: item,
      };
    });
    return list;
  };

  React.useEffect(() => {
    programsList();
    getFormattedData();
  }, []);

  // Data grid values
  const columns = [
    { field: "id", headerName: "ID", width: 70, sortable: false },

    { field: "name", headerName: "Name", width: 350, sortable: false },
    { field: "email", headerName: "Email", width: 350, sortable: false },
    { field: "contact", headerName: "Contact", width: 150, sortable: false },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 130,
    //   renderCell: (params) => {
    //     return (
    //       // Making A chip with status
    //       <Chip
    //         width="140px"
    //         label={
    //           params.getValue(params.id, "status") === true
    //             ? "Active"
    //             : "Inactive"
    //         }
    //         variant="contained"
    //         className={
    //           params.getValue(params.id, "status") === true
    //             ? "manage-program-chip-success"
    //             : ""
    //         }
    //         color={
    //           params.getValue(params.id, "status") === true
    //             ? "success"
    //             : "error"
    //         }
    //         size="small"
    //       />
    //     );
    //   },
    // },
  ];
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  // console.log(selectionModel, "selectionModel");
  // console.log(expiryDate, "expiryDateexpiryDate");

  return (
    <div className="container">
      <div style={{ width: "100%" }}>
        <h2 className="mb-4">News Letter</h2>
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
          }}
          className="dataGridStyle-news-letter"
          autoHeight
          disableColumnFilter={true}
          rows={getFormattedData()}
          disableSelectionOnClick
          columns={columns}
          pageSize={50}
          // rowsPerPageOptions={[5]}
          // checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            console.log(newSelectionModel);
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          ColumnSortedAscendingIcon={null}
          ColumnUnsortedIcon={null}
          ColumnSortedDescendingIcon={null}
          sortIcon={null}
          disableColumnMenu={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
      {/* <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div> */}
    </div>
  );
}
