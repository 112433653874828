import { invokeApi } from "../../bl_libs/invokeApi";

export const addWebsitePodCast = async (data) => {
  const requestObj = {
    path: `/api/website_pods/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editWebsitePodCast = async (data, id) => {
  const requestObj = {
    path: `/api/website_pods/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const listWebsitePodCast = async (data) => {
  const requestObj = {
    path: `/api/website_pods`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteWebsitePodCast = async (id) => {
  const requestObj = {
    path: `/api/website_pods/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
