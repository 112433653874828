import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress } from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";
import { object } from "prop-types";
import { menuList } from "src/DAL/Menu/Menu";
import { AddProgrammeApi } from "src/DAL/Programme/Programme";
import TinyEditor from "../../components/ckeditor/Ckeditor";
import { addQuotesApi } from "src/DAL/quotes/Quotes";
import {
  clientSettingApi,
  updateClientGeneralSettingApi,
  updateConsultantGeneralSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { s3baseUrl } from "src/config/config";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function UpdateConsultantGeneralSetting() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setiswaiting] = useState(false);
  const theme = useTheme();
  const [value, setValue] = React.useState("Controlled");
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [audioFile, setAudio] = React.useState();
  const [groups, setGroups] = React.useState([]);
  const [programList, setProgramList] = React.useState([]);
  const [menuLists, setMenuList] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  const [programName, setProgramName] = React.useState("");
  const [vaultName, setVaultName] = React.useState("");
  const [navItems, setNavitems] = React.useState([]);
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState("");
  const [footerLogo, setFooterLogo] = React.useState();
  const [brandFavIcon, setBrandFavIcon] = React.useState();
  const [clientGeneralSetting, setClientGeneralSetting] = useState();
  const [oldBrandLogo, setOldBrandLogo] = useState("");
  const [oldFooterLogo, setOldFooterLogo] = useState("");
  const [oldFav, setOldFav] = useState("");
  const [brandLogoStatus, setBrandLogoStatus] = useState(false);
  const [favStatus, setFavStatus] = useState(false);
  const [footerStatus, setFooterStatus] = useState(false);

  const [inputs, setInputs] = React.useState({
    title: "",
    description: "",
    brandLogo: {},
    footerLogo: {},
    brandFavIcon: {},
    brandName: "",
  });

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code == 200) {
      setIsLoading(false);
      //console.log(result, "okokoko");
      let generalSetting = result?.content_setting?.consultant_general_setting;
      //console.log(result, "general setting api");

      setInputs((prevState) => ({
        ...prevState,
        ["title"]: generalSetting.meta_title,
        ["description"]: generalSetting.meta_description,
        ["brandName"]: generalSetting.brand_name,
      }));
      setOldBrandLogo(generalSetting.brand_logo);
      setOldFav(generalSetting.brand_favicon);
      setOldFooterLogo(generalSetting.footer_logo);
    }
  };

  const fileChangedHandlerBrandLogo = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setBrandLogoStatus(true);
    setInputs({
      ...inputs,
      ["brandLogo"]: e.target.files[0],
    });
  };

  const fileChangedHandlerFooterLogo = (e) => {
    setFooterLogo(URL.createObjectURL(e.target.files[0]));
    setFooterStatus(true);
    setInputs({
      ...inputs,
      ["footerLogo"]: e.target.files[0],
    });
  };
  const fileChangedHandlerBrandFavIcon = (e) => {
    setBrandFavIcon(URL.createObjectURL(e.target.files[0]));
    setFavStatus(true);
    setInputs({
      ...inputs,
      ["brandFavIcon"]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    //console.log(detailDescriptionCk, "lolololo");
    e.preventDefault();

    const formData = new FormData();
    formData.append("meta_title", inputs.title);
    formData.append("meta_description", inputs.description);
    formData.append("brand_name", inputs.brandName);

    if (brandLogoStatus) {
      formData.append("brand_logo", inputs.brandLogo);
    }
    if (favStatus) {
      formData.append("brand_favicon", inputs.brandFavIcon);
    }

    //console form data
    //console.log(...formData, "formData");

    setIsLoading(true);
    const result = await updateConsultantGeneralSettingApi(formData);
    if (result.code === 200) {
      //console.log(result, "result");
      clientDetail();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      //console.log(result, "error case");
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //console.log(inputs, "value");
  React.useEffect(() => {
    clientDetail();
  }, []);

  //   console.log(typeof audioFile, "values of audio to be send ");

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/addreview/`)}
          >
            Add Programme Review
          </button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>General Settings</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Meta title for client"
            variant="outlined"
            fullWidth
            required
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Meta description for client"
            variant="outlined"
            fullWidth
            required
            name="description"
            value={inputs.description}
            onChange={handleChange}
          />
        </div>
        <h3 className="mt-3">General Information</h3>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Brand Name"
            variant="outlined"
            fullWidth
            required
            name="brandName"
            value={inputs.brandName}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Brand Logo</p>
              <FormHelperText className="pt-0">
                (Recommended Size 150 X 22)
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img src={file} height="50" />
              ) : (
                <img src={s3baseUrl + oldBrandLogo} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandlerBrandLogo}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.brandLogo?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.brandLogo?.name}</p>
          )}
        </div>
        {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Footer Logo</p>
              <FormHelperText className="pt-0">
                (Recommended Size 150 X 22)
              </FormHelperText>
            </div>
            <div className="col-2">
              {footerLogo ? (
                <img src={footerLogo} height="50" />
              ) : (
                <img src={s3baseUrl + oldFooterLogo} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-fileFooter">
                <Input
                  accept="image/*"
                  id="contained-button-fileFooter"
                  multiple
                  type="file"
                  name="footerLogo"
                  onChange={fileChangedHandlerFooterLogo}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.footerLogo?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.footerLogo?.name}</p>
          )}
        </div> */}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Brand FavIcon</p>
              <FormHelperText className="pt-0">
                (Recommended Size 32 X 32)
              </FormHelperText>
            </div>
            <div className="col-2">
              {brandFavIcon ? (
                <img src={brandFavIcon} height="50" />
              ) : (
                <img src={s3baseUrl + oldFav} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-fileFavIcon">
                <Input
                  accept="image/*"
                  id="contained-button-fileFavIcon"
                  multiple
                  type="file"
                  name="brandFavIcon"
                  onChange={fileChangedHandlerBrandFavIcon}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.brandFavIcon?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.brandFavIcon?.name}</p>
          )}
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
