import { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import { IconButton, Button } from "@mui/material";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { ContextPGIMode, usePGIMode } from "../../Hooks/PGIModeContext";
import { Helmet } from "react-helmet-async";
import { s3baseUrl } from "src/config/config";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 65;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { favIconImage, metaDescription, metaTitle } = usePGIMode();
  const location = useLocation();

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
  // else if (!localStorage.getItem("user_data")) {
  //   localStorage.clear();
  //   return <Navigate to="/login"> </Navigate>;
  // }
  useEffect(() => {
    if (!location.pathname.includes("member")) {
      localStorage.removeItem("member_list_object");
    }
  }, [location]);
  return (
    <RootStyle>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="icon" type="image/x-icon" href={s3baseUrl + favIconImage} />
      </Helmet>
      {/* <ContextPGIMode> */}
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle>
        <Outlet />
      </MainStyle>
      {/* </ContextPGIMode> */}
    </RootStyle>
  );
}
