import { Box, List } from "@mui/material";
import NavSectionItem from "./NavSectionItem";
import { defaultSettingApi } from "src/DAL/SiteSetting/siteSetting";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

export default function NavSection({ navConfig }) {
  const { setFavIconImage, setMetaTitle, setMetaDescription } = usePGIMode();
  const [searchInput, setSearchInput] = useState("");
  const getNavItemsList = (array, query) => {
    if (query) {
      const _nav_list = array.filter(
        (data) =>
          data.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          data.child_options?.some(
            (item) =>
              item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
          )
      );
      // .map((data) => {
      //   return {
      //     ...data,
      //     child_options: data.child_options?.filter(
      //       (item) =>
      //         item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
      //     ),
      //   };
      // });
      return _nav_list;
    }
    return array;
  };
  const getSetting = async () => {
    const result = await defaultSettingApi();
    if (result.code == 200) {
      localStorage.setItem("favIcon", result.default_setting.admin_favicon);
      localStorage.setItem("adminLogo", result.default_setting.admin_logo);
      localStorage.setItem("metaTitle", result.default_setting.meta_title);
      localStorage.setItem(
        "metaDescription",
        result.default_setting.meta_description
      );
      setFavIconImage(result.default_setting.admin_favicon);
      setMetaTitle(result.default_setting.meta_title);
      setMetaDescription(result.default_setting.meta_description);
    }
  };

  useEffect(() => {
    getSetting();
  }, []);
  return (
    <Box>
      <div className="my-1 p-2">
        <div className="sidebar-search-box">
          <Icon
            fontSize={20}
            className="sidebar-search-icon"
            icon="akar-icons:search"
          />
          <input
            className="sidebar-search-input"
            type="text"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
      </div>
      <List disablePadding>
        {getNavItemsList(navConfig, searchInput).map((item, i) => {
          return (
            <NavSectionItem key={i} data={item} searchInput={searchInput} />
          );
        })}
      </List>
    </Box>
  );
}
