import { Container } from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get_book_pdf_data_api } from "src/DAL/Books/Books";

export default function BookPreview() {
  const { file_url } = useParams();
  const iframeRef = useRef(null);
  const [isDocumentLoading, setIsDocumentLoading] = useState(true);

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };

  const get_book_preview = async () => {
    let data = { file_name: file_url };
    let response = await get_book_pdf_data_api(data);
    if (iframeRef.current) {
      iframeRef.current.contentDocument.open();
      iframeRef.current.contentDocument.write(response);
      iframeRef.current.contentDocument.close();
    }
    setIsDocumentLoading(false);
  };

  useEffect(() => {
    get_book_preview();
  }, []);

  return (
    <Container>
      <div className="row mobile-margin display-flex">
        <div className="row">
          <div onContextMenu={handleContextMenu} className="pdf-view">
            {isDocumentLoading && (
              <div className="loading-pdf mt-5">
                <h2>Loading Pdf ...</h2>
              </div>
            )}
            <iframe
              ref={iframeRef}
              style={{ width: "100%", height: "100vh" }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
