import React, { useEffect, useState } from "react";
import { List, Collapse, ListItemText, ListItemButton } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  matchPath,
  useLocation,
  NavLink as RouterLink,
} from "react-router-dom";

export default function NavSectionItem({ data, searchInput }) {
  const { pathname } = useLocation();
  const [openDropdown, setOpenDropdown] = useState(false);

  const match = ({ path, matches }) => {
    if (matches) {
      let is_active = false;
      matches.forEach((match_path) => {
        const match = match_path
          ? !!matchPath({ path: match_path, end: false }, pathname)
          : false;

        if (match) {
          is_active = true;
        }
      });
      return is_active;
    }
    return path ? !!matchPath({ path, end: false }, pathname) : false;
  };

  const handleClickDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  // useEffect(() => {
  //   //On reload dropdown should not close
  //   data.child_options?.map((child_option) => {
  //     if (child_option.path === pathname) {
  //       setOpenDropdown(true);
  //     }
  //   });
  // }, []);
  useEffect(() => {
    //On reload dropdown should not close
    data.child_options?.map((child_option) => {
      if (child_option.matches) {
        child_option.matches.map((match) => {
          const is_match_path = window.location.pathname.includes(match);
          if (is_match_path || searchInput) {
            setOpenDropdown(true);
          }
        });
      }
      const is_path = window.location.pathname.includes(child_option.path);
      if (is_path || searchInput) {
        setOpenDropdown(true);
      }
    });
  }, [searchInput]);

  return (
    <>
      <ListItemButton
        component={data.path ? RouterLink : "a"}
        to={data.path ? data.path : ""}
        sx={{ pl: 5, fontSize: "14px" }}
        onClick={handleClickDropdown}
        className={
          match({ path: data.path, matches: data.matches })
            ? "menuActive menus-list"
            : "menus-list"
        }>
        {data.icon}
        <ListItemText
          primaryTypographyProps={{ fontSize: "14px" }}
          primary={data.title}
        />

        {/* This will only work in case of dropdown*/}
        {data.child_options && (openDropdown ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>

      {/* This will only work in case of dropdown*/}
      {data.child_options && (
        <Collapse in={openDropdown} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {data.child_options.map((child_option, index) => {
              return (
                <ListItemButton
                  component={RouterLink}
                  to={child_option.path}
                  key={index}
                  sx={{
                    pl: 8,
                    color: "black",
                    fontSize: "10px",
                  }}
                  className={
                    match({
                      path: child_option.path,
                      matches: child_option.matches,
                    })
                      ? "menuActive menus-list"
                      : "menus-list"
                  }>
                  {child_option.icon}
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "14px" }}
                    primary={child_option.title}
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
}
