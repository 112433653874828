import { invokeApi } from "../../bl_libs/invokeApi";

export const businessPartnerListApi = async (id) => {
  let api_path = `/api/business_partner/business_partner_list_for_general`;
  if (id) {
    api_path = `/api/business_partner/business_partner_list_for_sale_page/${id}`;
  }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addBusinessPartnerApi = async (data) => {
  const requestObj = {
    path: `/api/business_partner/add_business_partner`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editBusinessPartnerApi = async (data, id) => {
  const requestObj = {
    path: `/api/business_partner/update_business_partner/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteBusinessPartnerApi = async (slug) => {
  const requestObj = {
    path: `/api/business_partner/delete_business_partner/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
