import { invokeApi } from "../../bl_libs/invokeApi";

export const booksDetailApi = async (id) => {
  const requestObj = {
    path: `/api/book/detail_book/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const booksListing = async (data) => {
  const requestObj = {
    path: `/api/book/list_book`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddBooksApi = async (data) => {
  const requestObj = {
    path: `/api/book/add_book`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditBooksApi = async (data, id) => {
  const requestObj = {
    path: `/api/book/update_book/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteBookApi = async (id) => {
  const requestObj = {
    path: `/api/book/delete_book/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const UploadPdfFile = async (data) => {
  const requestObj = {
    path: `/api/book/upload_book_pdf`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UnassignedMembersAgainstProgram = async (data) => {
  const requestObj = {
    path: `/api/book/unassigned_members_against_program`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_book_pdf_data_api = async (data) => {
  const requestObj = {
    path: `/api/book/get_book_pdf_data`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
