import { invokeApi } from "../../bl_libs/invokeApi";

export const successDetailListApi = async (id) => {
  const requestObj = {
    path: `/api/success/success_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const successListApi = async (id) => {
  let api_path = `/api/success/success_list_general`;
  if (id) {
    api_path = `/api/success/success_list_for_sale_page/${id}`;
  }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addSuccessApi = async (data) => {
  const requestObj = {
    path: `/api/success/add_success`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editSuccessApi = async (data, id) => {
  const requestObj = {
    path: `/api/success/update_success/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteSuccessApi = async (id) => {
  const requestObj = {
    path: `/api/success/delete_success/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
