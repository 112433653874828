import { invokeApi } from "../../bl_libs/invokeApi";

export const bookCategoryListApi = async (id) => {
  let api_path = `/api/book_category/book_category_list_general`;
  if (id) {
    api_path = `/api/book_category/book_category_list_for_sale_page/${id}`;
  }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addBookCategoryApi = async (data) => {
  const requestObj = {
    path: `/api/book_category/add_book_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editBookCategoryApi = async (data, slug) => {
  const requestObj = {
    path: `/api/book_category/update_book_category/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteBookCategoryApi = async (slug) => {
  const requestObj = {
    path: `/api/book_category/delete_book_category/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
